let center = {
  "vgy": {
    lat: 59.305796,
    lng: 18.086584,
  },
  "init": {
    lat: 59.33769336437306,
    lng: 18.050591648302813,
  }
}

export default center;
