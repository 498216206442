const user = {
  username: "davidbjlund",
  password: "password123",
  mode: 0,
  //mode: 1,
  //school: "init",
  school: "vgy",
  zoom: 14,
  dblzoom: false,
  myposition: false,
  like: {
    "vgy": [
      "Berliner Kebab",
      "Cicada",
    ],
    "init": [
      "Taco Bar",
    ]
  }
}

export default user;
